import { useEffect, useState } from "react";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { Image as IImage } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { RichText } from "../../internal-components/richText/RichText.js";
import { LinkList } from "../../internal-components/linkList/linkList.js";
import { arrow } from "../../icons/svg.js";

interface Button {
  link?: Link;
}

interface Image {
  image?: IImage;
}

interface Props {
  strapline?: string;
  title?: string;
  text?: string;
  centerText?: boolean;
  showImage?: boolean;
  image?: IImage;
  images?: Image[];
  buttons?: Button[];
}

const Hero: React.FC<Props> = ({
  strapline,
  title,
  text,
  centerText,
  showImage,
  image,
  images = [],
  buttons = [],
}) => {
  const store = useStore();
  const page = store.context.page;
  const lastParent = page.parents[page.parents.length - 1];
  const usedTitle = title || page.title;
  const usedText = text || page.excerpt;
  const usedButtons = buttons.filter((b) => b.link);
  const usedImage = image || page.image;

  if (showImage) {
    if (usedImage && images.length > 0) {
      images = [{ image: usedImage }, ...images];
    } else if (usedImage) {
      images = [{ image: usedImage }];
    }
  } else {
    images = [];
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (showImage && images.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((currentIndex + 1) % images.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, images.length, showImage]);

  const content = (
    <>
      <h1>{usedTitle}</h1>
      {usedText && (
        <RichText
          className={centerText ? "centered" : undefined}
          html={usedText}
        />
      )}
    </>
  );

  if (images.length > 0) {
    return (
      <div className="hero with-image v-center">
        {images.length >= 0 && (
          <div className="image-container">
            {images.map((image, i) => (
              <img
                key={i}
                src={transformImageUrl(image.image!, { height: 1200 })}
                alt={image.image!.altText}
                className={i === currentIndex ? "active" : ""}
              />
            ))}
          </div>
        )}
        <div className="hs mw">
          {(lastParent || strapline) && (
            <LinkList>
              {lastParent && !strapline && (
                <a href={lastParent.url}>{lastParent.title}</a>
              )}
              {strapline && <span>{strapline}</span>}
            </LinkList>
          )}
          <div className="rich-text">{content}</div>
          {buttons.length > 0 && (
            <div className="button-list">
              {usedButtons.slice(0, 2).map((b, i) => (
                <a
                  key={i}
                  className={`button${i % 2 ? " secondary" : ""}`}
                  href={b.link!.url}
                  aria-label={b.link!.ariaLabel}
                >
                  {b.link!.title}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="hero rich-text vs-xxl hs mw mw-small">
      {content}
      {usedButtons.length > 0 && (
        <div className="button-list">
          {usedButtons.map((b, i) => (
            <a
              key={i}
              className={`button 
              }`}
              href={b.link!.url}
              aria-label={b.link!.ariaLabel}
            >
              {b.link!.title} {arrow}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Hero;
